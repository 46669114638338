import React from 'react';

export default (props) => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <strong>Correction Factor / Standard Unit Values</strong><div className="pull-right">
          <div className="form-inline">
            <div className="form-group">
              <strong>Total Correction </strong>
              <input className="form-control input-sm sizer-total-textbox" disabled="disabled"
                id="TotalCorrectionFactorDisplay" name="TotalCorrectionFactorDisplay" readOnly="readonly" type="text" value={props.totalCorrectionFactor} />
            </div>
          </div>
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-md-3">
            &nbsp;
         </div>
          <div className="col-md-3">
            <label className="control-label">Application</label>
          </div>
          <div className="col-md-3">
            <label className="control-label">Standard</label>
          </div>
          <div className="col-md-3">
            <label className="control-label">Correction Factor</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label">Fluid Density</label>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="ApplicationDensityDisplay" name="ApplicationDensityDisplay"
                readOnly="readonly" type="text" value={props.applicationDensity} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="StandardDensityDisplay" name="StandardDensityDisplay"
                readOnly="readonly" type="text" value={props.standardDensity} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="DensityCorrectionFactorDisplay" name="DensityCorrectionFactorDisplay"
                readOnly="readonly" type="text" value={props.densityCorrectionFactor} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label">Operating Temperature</label>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="ApplicationTemperatureDisplay" name="ApplicationTemperatureDisplay"
                readOnly="readonly" type="text" value={props.applicationTemperature} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="StandardTemperatureDisplay" name="StandardTemperatureDisplay"
                readOnly="readonly" type="text" value={props.standardTemperature} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="TemperatureCorrectionFactorDisplay" name="TemperatureCorrectionFactorDisplay"
                readOnly="readonly" type="text" value={props.temperatureCorrectionFactor} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label">Operating Pressure</label>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="ApplicationPressureDisplay" name="ApplicationPressureDisplay"
                readOnly="readonly" type="text" value={props.applicationPressure} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="StandardPressureDisplay" name="StandardPressureDisplay"
                readOnly="readonly" type="text" value={props.standardPressure} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="PressureCorrectionFactorDisplay" name="PressureCorrectionFactorDisplay"
                readOnly="readonly" type="text" value={props.pressureCorrectionFactor} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            &nbsp;
         </div>
          <div className="col-md-3">
            <label className="control-label">Application</label>
          </div>
          <div className="col-md-3">
            <label className="control-label">Convert To</label>
          </div>
          <div className="col-md-3">
            <label className="control-label">{props.flowRateCorrectionFactorLabel}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label">Max Flow Rate</label>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="MaximumFlowUnits" name="MaximumFlowUnits" readOnly="readonly"
                type="text" value={props.applicationFlowRate} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="TotalConversionOneDisplay" name="TotalConversionOneDisplay" readOnly="readonly"
                type="text" value={props.flowRateCorrectionFactorOneUnit} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="CorrectionFactorOneDisplay" name="CorrectionFactorOneDisplay" readOnly="readonly"
                type="text" value={props.flowRateCorrectionFactorOne} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            &nbsp;
         </div>
          <div className="col-md-3">
            <div className="form-group">
              &nbsp;
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="TotalConversionTwoDisplay" name="TotalConversionTwoDisplay" readOnly="readonly"
                type="text" value={props.flowRateCorrectionFactorTwoUnit} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="CorrectionFactorTwoDisplay" name="CorrectionFactorTwoDisplay" readOnly="readonly"
                type="text" value={props.flowRateCorrectionFactorTwo} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            &nbsp;
         </div>
          <div className="col-md-3">
            <div className="form-group">
              &nbsp;
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="TotalConversionThreeDisplay" name="TotalConversionThreeDisplay" readOnly="readonly"
                type="text" value={props.flowRateCorrectionFactorThreeUnit} />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input className="form-control input-sm" disabled="disabled" id="CorrectionFactorThreeDisplay" name="CorrectionFactorThreeDisplay" readOnly="readonly"
                type="text" value={props.flowRateCorrectionFactorThree} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
