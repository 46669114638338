import React, { Component } from 'react';
import SizingParameters from './SizingParameters';
import SizingResults from './SizingResults';
import GeneratePdfResult from './GeneratePdfResult';

function formatNumber(number, maximumFractionDigits) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits }).format(number);
}

export class SizingCalculator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      totalCorrectionFactor: '',
      applicationDensity: '',
      standardDensity: '1 Sp. Gr.',
      densityCorrectionFactor: '',
      applicationTemperature: '',
      standardTemperature: '70 F',
      temperatureCorrectionFactor: '',
      applicationPressure: '',
      standardPressure: '14.7 PSIG',
      pressureCorrectionFactor: '',
      flowRateCorrectionFactorLabel: 'AIR @ STP',
      applicationFlowRate: '',
      flowRateCorrectionFactorOne: '',
      flowRateCorrectionFactorOneUnit: '',
      flowRateCorrectionFactorTwo: '',
      flowRateCorrectionFactorTwoUnit: '',
      flowRateCorrectionFactorThree: '',
      flowRateCorrectionFactorThreeUnit: '',
      fluidType: 1,
      fluidName: 'Air'
    };
  }

  async calculateResult(parameters) {
    try {
      const calcultedResult = await fetch('api/sizer/calculate', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parameters)
      });

      if (calcultedResult.ok) {
        const jsonResult = await calcultedResult.json();
        this.setState(this.formatResult({ ...jsonResult }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async calculateAndGeneratesPdf(parameters) {
    await this.calculateResult(parameters);
    GeneratePdfResult({ ...this.state });
  }

  formatResult(apiResult) {

    const totalCorrectionFactor = formatNumber(apiResult.totalCorrectionFactor, 4);
    const applicationDensity = `${formatNumber(apiResult.density, 4)} ${apiResult.densityUnit}`;
    const standardDensity = `${formatNumber(apiResult.standardDensity, 4)} ${apiResult.standardDensityUnit}`;
    const densityCorrectionFactor = formatNumber(apiResult.densityCorrectionFactor, 4);

    const applicationTemperature = `${formatNumber(apiResult.temperature, 2)} ${apiResult.temperatureUnit} `;
    const standardTemperature = `${formatNumber(apiResult.standardTemperature, 2)} ${apiResult.standardTemperatureUnit} `;
    const temperatureCorrectionFactor = formatNumber(apiResult.temperatureCorrectionFactor, 4);

    const applicationPressure = `${formatNumber(apiResult.pressure, 4)} ${apiResult.pressureUnit ? apiResult.pressureUnit : ''} `;
    const standardPressure = `${formatNumber(apiResult.standardPressure, 4)} ${apiResult.standardPressureUnit ? apiResult.standardPressureUnit : ''} `;
    const pressureCorrectionFactor = formatNumber(apiResult.pressureCorrectionFactor, 4);

    const flowRateCorrectionFactorLabel = parseInt(apiResult.sizerParameters.fluidType) === 1 ? 'AIR @ STP' : 'WATER @ STP';
    const applicationFlowRate = `${formatNumber(apiResult.flowRate, 2)} ${apiResult.flowRateUnit === 'Unknown' ? '' : apiResult.flowRateUnit} `;
    const flowRateCorrectionFactorOne = formatNumber(apiResult.flowRateCorrectionFactorOne, 4);
    const flowRateCorrectionFactorOneUnit = apiResult.flowRateCorrectionFactorOneUnit;
    const flowRateCorrectionFactorTwo = formatNumber(apiResult.flowRateCorrectionFactorTwo, 4);
    const flowRateCorrectionFactorTwoUnit = apiResult.flowRateCorrectionFactorTwoUnit;
    const flowRateCorrectionFactorThree = formatNumber(apiResult.flowRateCorrectionFactorThree, 4);
    const flowRateCorrectionFactorThreeUnit = apiResult.flowRateCorrectionFactorThreeUnit;
    const fluidType = parseInt(apiResult.sizerParameters.fluidType) === 1 ? 'Gas' : 'Liquid';
    const floatMaterialName = apiResult.sizerParameters.floatMaterialName;
    const fluidName = apiResult.sizerParameters.fluidName;
    return {
      totalCorrectionFactor,
      applicationDensity,
      standardDensity,
      densityCorrectionFactor,
      applicationTemperature,
      standardTemperature,
      temperatureCorrectionFactor,
      applicationPressure,
      standardPressure,
      pressureCorrectionFactor,
      flowRateCorrectionFactorLabel,
      applicationFlowRate,
      flowRateCorrectionFactorOne,
      flowRateCorrectionFactorOneUnit,
      flowRateCorrectionFactorTwo,
      flowRateCorrectionFactorTwoUnit,
      flowRateCorrectionFactorThree,
      flowRateCorrectionFactorThreeUnit,
      fluidName,
      fluidType,
      floatMaterialName
    }
  }

  render() {
    return (
      <>
        <SizingParameters calculate={this.calculateResult.bind(this)} calculatePdf={this.calculateAndGeneratesPdf.bind(this)}></SizingParameters>
        <SizingResults {...this.state}></SizingResults>
      </>
    );
  }
}
