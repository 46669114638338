import React, { Component } from 'react';

export default class SizingParameters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.data = {
      fluidTypes: [],
      gasses: [],
      liquids: [],
      gasFlowRateUnits: [],
      liquidFlowRateUnits: [],
      temperatureUnits: [],
      pressureUnits: [],
      floatMaterials: []
    };

    fetch('api/sizer/get-all-data')
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.data = data;
        this.fluidTypeOptions = this.data.fluidTypes.map(fluidType => <option key={fluidType.value} value={fluidType.value}>{fluidType.description}</option>);
        this.gassesOptions = this.data.gasses.map(gas => <option key={gas.name} value={gas.name}>{gas.name}</option>);
        this.liquidOptions = this.data.liquids.map(liquid => <option key={liquid.name} value={liquid.name}>{liquid.name}</option>);
        this.gasFlowRateUnitsOptions = this.data.gasFlowRateUnits.map(gasUnit => <option key={gasUnit.value} value={gasUnit.value}>{gasUnit.description}</option>);
        this.liquidFlowRateUnitsOptions = this.data.liquidFlowRateUnits.map(liquidUnit => <option key={liquidUnit.value} value={liquidUnit.value}>{liquidUnit.description}</option>);
        this.temperatureUnitsOptions = this.data.temperatureUnits.map(temperatureUnit => <option key={temperatureUnit.value} value={temperatureUnit.value}>{temperatureUnit.description}</option>);
        this.pressureUnitsOptions = this.data.pressureUnits.map(pressureUnit => <option key={pressureUnit.value} value={pressureUnit.value}>{pressureUnit.description}</option>);
        this.floatMaterialsOptions = this.data.floatMaterials.map(floatMaterial => <option key={floatMaterial.value} value={floatMaterial.value}>{floatMaterial.description}</option>);

        this.setState({
          loading: false,
          fluidType: 1,
          selectedGas: 'Air',
          selectedLiquid: 'Water',
          fluidName: 'Air',
          density: 1.0,
          flowRate: 0,
          flowRateGasUnit: 24,
          flowRateLiquidUnit: 37,
          flowRateUnit: 24,
          temperature: 70,
          temperatureUnit: 70,
          pressure: 0,
          pressureUnit: 67,
          floatMaterial: 0
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  fluidTypeChanged(event) {
    const fluidTypeValue = parseInt(event.target.value);
    const flowRateUnit = fluidTypeValue === 1 ? this.state.flowRateGasUnit : this.state.flowRateLiquidUnit;
    const fluidName = fluidTypeValue === 1 ? this.state.selectedGas : this.state.selectedLiquid;

    this.setState({ fluidType: fluidTypeValue, flowRateUnit, fluidName });
    this.fluidChanged(fluidName, fluidTypeValue);
  }

  fluidChanged(fluidValue, fluidType) {
    if ((this.selectedFluidTypeIsGas(fluidType))) {
      const fluidFounds = this.data.gasses.filter(fluid => fluid.name === fluidValue);
      if (fluidFounds.length > 0) {
        const fluid = fluidFounds[0];
        this.setState({ selectedGas: fluid.name, fluidName: fluid.name, density: fluid.density });
      }
    } else {
      const fluidFounds = this.data.liquids.filter(fluid => fluid.name === fluidValue);
      if (fluidFounds.length > 0) {
        const fluid = fluidFounds[0];
        this.setState({ selectedLiquid: fluid.name, fluidName: fluid.name, density: fluid.density });
      }
    }
  }

  updateValue(parameter, value) {
    let floatValue = parseFloat(value) || 0;
    const newValues = { [parameter]: floatValue }

    if (parameter === 'flowRateGasUnit' || parameter === 'flowRateLiquidUnit') {
      const flowRateUnit = floatValue;
      newValues.flowRateUnit = flowRateUnit;
    }

    if (parameter === 'density') {
      if (this.selectedFluidTypeIsGas()) {
        newValues.selectedGas = 'User Defined Gas';
        newValues.fluidName = 'User Defined Gas';
      } else {
        newValues.selectedLiquid = 'User Defined Liquid';
        newValues.fluidName = 'User Defined Liquid';
      }
    }

    this.setState({ ...newValues });
  }

  selectedFluidTypeIsGas(fluidType) {
    if (fluidType) {
      return parseInt(fluidType) === 1
    }
    return parseInt(this.state.fluidType) === 1;
  }

  calculate() {
    this.props.calculate({ ...this.state });
  }

  calculatePdf() {
    this.props.calculatePdf({ ...this.state });
  }

  renderOnceIsLoaded() {
    const fluidsToShow = this.selectedFluidTypeIsGas() ? (
      <div className="form-group">
        <label className="control-label" htmlFor="Gas">Gas Name</label>
        <div>
          <select className="form-control input-sm substance-select" id="Gas" name="Gas"
            value={this.state.selectedGas} onChange={(event) => this.fluidChanged(event.target.value, this.state.fluidType)} >
            {this.gassesOptions}
          </select>
        </div>
      </div>
    ) : (
        <div className="form-group">
          <label className="control-label" htmlFor="Liquid">Liquid Name</label>
          <div>
            <select className="form-control input-sm substance-select" id="Liquid" name="Liquid"
              value={this.state.selectedLiquid} onChange={(event) => this.fluidChanged(event.target.value, this.state.fluidType)} >
              {this.liquidOptions}
            </select>
          </div>
        </div>
      );

    const flowRatesToShow = this.selectedFluidTypeIsGas() ?
      (
        <select className="form-control input-sm" id="ddlFlowRateUnits" name="FlowRateUnits"
          value={this.state.flowRateGasUnit} onChange={(event) => this.updateValue('flowRateGasUnit', event.target.value)}        >
          <option value="">[Select One]</option>
          {this.gasFlowRateUnitsOptions}
        </select>
      ) :
      (
        < select className="form-control input-sm" id="ddlFlowRateUnits" name="FlowRateUnits"
          value={this.state.flowRateLiquidUnit} onChange={(event) => this.updateValue('flowRateLiquidUnit', event.target.value)
          } >
          <option value="">[Select One]</option>
          {this.liquidFlowRateUnitsOptions}
        </select >
      );

    const lastParameter = this.selectedFluidTypeIsGas() ? (
      <div className="panel panel-default group-box">
        <div className="panel-heading group-heading">
          Back Pressure
        </div>
        <div className="panel-body">
          <div className="col-md-4">
            <div className="form-group group-form-group">
              <label className="control-label" htmlFor="OperatingPressure">Operating</label>
              <input className="form-control input-sm text-box single-line" id="OperatingPressure" name="OperatingPressure" type="number"
                value={this.state.pressure} onChange={event => this.updateValue('pressure', event.target.value)} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group group-form-group">
              <label className="control-label" htmlFor="PressureUnit">Units</label>
              <select className="form-control input-sm" id="PressureScale" name="PressureScale"
                value={this.state.pressureUnit} onChange={(event) => this.updateValue('pressureUnit', event.target.value)} >
                <option value="">[Select One]</option>
                {this.pressureUnitsOptions}
              </select>
            </div>
          </div>
        </div>
      </div>) :
      (
        <div className="panel panel-default group-box">
          <div className="panel-heading group-heading">
            Float Material
               </div>
          <div className="panel-body">
            <div className="col-md-8">
              <div className="form-group group-form-group">
                <label className="control-label" htmlFor="PressureUnit">Material</label>
                <select className="form-control input-sm" id="FloatMaterial" name="FloatMaterial"
                  value={this.state.floatMaterial} onChange={(event) => this.updateValue('floatMaterial', event.target.value)}>
                  <option value="">[Select One]</option>
                  {this.floatMaterialsOptions}
                </select>
              </div>
            </div>
          </div>
        </div>
      );


    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4><strong>Sizing Calculator</strong></h4>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label className="control-label" htmlFor="MatterType">Liquid or Gas</label>
                <select className="form-control input-sm" name="MatterType" value={this.state.fluidType} onChange={this.fluidTypeChanged.bind(this)}>
                  {this.fluidTypeOptions}
                </select></div>
            </div>
            <div className="col-md-4">
              {fluidsToShow}
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="Density">Density (Specific Gravity)</label>
                <div>
                  <input className="form-control input-sm sizer-inline-textbox text-box single-line" id="DensityValue" name="DensityValue"
                    type="number" value={this.state.density} onChange={event => this.updateValue('density', event.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="panel panel-default group-box">
                <div className="panel-heading group-heading">
                  Flow Rate
               </div>
                <div className="panel-body">
                  <div className="col-md-4">
                    <div className="form-group group-form-group">
                      <label className="control-label" htmlFor="OperatingFlowRate">Operating</label>
                      <input className="form-control input-sm text-box single-line" id="OperatingFlowRate" name="OperatingFlowRate"
                        type="number" value={this.state.flowRate} onChange={(event) => this.updateValue('flowRate', event.target.value, 2)} /></div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group group-form-group">
                      <label className="control-label" htmlFor="FlowRateUnit">Units</label>
                      {flowRatesToShow}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="panel panel-default group-box">
                <div className="panel-heading group-heading">
                  Temperature
               </div>
                <div className="panel-body">
                  <div className="col-md-4">
                    <div className="form-group group-form-group">
                      <label className="control-label" htmlFor="OperatingTemperature">Operating</label>
                      <input className="form-control input-sm text-box single-line" id="OperatingTemperature" name="OperatingTemperature"
                        type="number" value={this.state.temperature} onChange={(event) => this.updateValue('temperature', event.target.value)} /></div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group group-form-group">
                      <label className="control-label" htmlFor="TemperatureUnit">Units</label>
                      <select className="form-control input-sm" id="dllTemperatureScale" name="TemperatureScale"
                        value={this.state.temperatureUnit} onChange={(event) => this.updateValue('temperatureUnit', event.target.value)}>
                        <option value="">[Select One]</option>
                        {this.temperatureUnitsOptions}
                      </select></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {lastParameter}
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <div className="row">
            <div className="col-md-6">
              <div className="form-inline">
                <div className="form-group">
                  <button className="btn btn-success btn-sm" onClick={this.calculate.bind(this)}>
                    <i className="glyphicon glyphicon-refresh"></i>
                    &nbsp;Calculate Correction Values
                  </button>
                  &nbsp;
                  <button className="btn btn-success btn-sm" onClick={this.calculatePdf.bind(this)}>
                    <i className="glyphicon glyphicon-print"></i>
                    &nbsp;Print Correction Values
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderOnceIsLoaded();

    return contents;
  }
}
